<script>
import {contact} from '../api/contactApi';


import {
  MailIcon,
 // PhoneIcon,
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  MapPinIcon
} from "vue-feather-icons";
import { 
  apiStatus
} from '../api/constants/apiStatus';
const { 
  IDLE,
  PENDING,
  SUCCESS,
  ERROR 
} = apiStatus;
import { apiStatusComputedFactory } from '../api/helpers/apiStatusComputedFactory';

export default {
  components: { 
    MailIcon,
    // PhoneIcon, 
    FacebookIcon, 
    TwitterIcon, 
    InstagramIcon,
    MapPinIcon 
  },
  data() {
    return {
      form : {
      name:"",
      subject:"",
      email: "",
      message: ""
      },
      createContactUsMessageStatus: IDLE
    };
  },
  methods: {
    async validateSubmit() {
      try {
        this.createContactUsMessageStatus = PENDING
        await contact(this.form);
        this.createContactUsMessageStatus = SUCCESS
        this.form = {
                  name:"",
                  subject:"",
                  email: "",
                  message: ""
                  };
        this.success();
      }catch(error) {
        console.log(error)
        this.createContactUsMessageStatus = ERROR
        this.error();
      }

    },
    error() {
      this.$swal({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    },
    success() {
      this.$swal({
        icon: 'success',
        title: 'Thanks',
        text: "Message Received. We'll be in touch shortly"

      })
    }
  },
  computed: {
    ...apiStatusComputedFactory(['createContactUsMessageStatus'])
  }
};
</script>

<template>
  <!-- Contact us start -->
  <section class="section" id="contact">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h2 class="fw-bold mb-4">Get in Touch</h2>
          <!-- <p class="text-muted mb-5">
            Et harum quidem rerum facilis est expedita distinctio temporecum
            soluta nobis est eligendi optio cumque nihil impedit quo minus
            maxime.
          </p> -->

          <div>
            <form method="post" name="myForm" @submit.prevent="validateSubmit()">
              <p id="error-msg"></p>
              <div id="simple-msg"></div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="mb-4">
                    <label for="name" class="text-muted form-label">Name</label>
                    <input
                      name="name"
                      id="name"
                      type="text"
                      class="form-control"
                      placeholder="Enter name*"
                      v-model="form.name"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-4">
                    <label for="email" class="text-muted form-label"
                      >Email</label
                    >
                    <input
                      name="email"
                      id="email"
                      type="email"
                      class="form-control"
                      placeholder="Enter email*"
                      v-model="form.email"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-4">
                    <label for="subject" class="text-muted form-label"
                      >Subject</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="subject"
                      name="subject"
                      placeholder="Enter Subject.."
                      v-model="form.subject"
                    />
                  </div>

                  <div class="mb-4 pb-2">
                    <label for="comments" class="text-muted form-label"
                      >Message</label
                    >
                    <textarea
                      name="comments"
                      id="comments"
                      rows="4"
                      class="form-control"
                      placeholder="Enter message..."
                      v-model="form.message"
                    ></textarea>
                  </div>

                  <button
                    type="submit"
                    id="submit"
                    name="send"
                    class="btn btn-primary"
                    :disabled="createContactUsMessageStatusPending"
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- end col -->

        <div class="col-lg-5 ms-lg-auto">
          <div class="mt-5 mt-lg-0">
            <img
              src="@/assets/images/contact.png"
              alt=""
              class="img-fluid d-block"
            />
            <p class="text-muted mt-5 mb-3">
              <a href="mailto:info@objectivesloutionsltd.com" class="text-muted">
              <mail-icon class="me-2 text-muted icon icon-xs"></mail-icon>
              info@objectivesloutionsltd.com
              </a>  
              
            </p>
            <!-- <p class="text-muted mb-3">
              <phone-icon class="me-2 text-muted icon icon-xs"></phone-icon>
              +91 123 4556 789
            </p> -->
            <p class="text-muted mb-3">
              <map-pin-icon
                class="me-2 text-muted icon icon-xs"
              ></map-pin-icon>
              Montego Bay, Jamaica
            </p>
            <ul class="list-inline pt-4">
              <li class="list-inline-item me-3">
                <a
                  href="javascript: void(0);"
                  class="social-icon icon-mono avatar-xs rounded-circle"
                  ><facebook-icon class="icon-xs"></facebook-icon
                ></a>
              </li>
              <li class="list-inline-item me-3">
                <a
                  href="javascript: void(0);"
                  class="social-icon icon-mono avatar-xs rounded-circle"
                  ><twitter-icon class="icon-xs"></twitter-icon
                ></a>
              </li>
              <li class="list-inline-item">
                <a
                  href="javascript: void(0);"
                  class="social-icon icon-mono avatar-xs rounded-circle"
                  ><instagram-icon class="icon-xs"></instagram-icon
                ></a>
              </li>
            </ul>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Contact us end -->
</template>